import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Messages = () => {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const fetchMessages = async () => {
      const response = await axios.get('https://zomblezoom.com/messages');
      setMessages(response.data);
    };

    fetchMessages();

    const interval = setInterval(fetchMessages, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <h1>Webhook Messages</h1>
      <ul>
        {messages.map((message, index) => (
          <li key={index}>
            <strong>{message.sender_name}</strong>: {message.message_content}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Messages;